import FileDataService from '@/services/FileDataService';
import File from '@/types/File';
import BasicDataHelper from './BasicDataHelper';

class FileDataHelper extends BasicDataHelper {
  static getObjectFromStore(store: any, val: string): File {
    /* UNDEFINED METHOD */
    return {} as File;
  }

  static prepareComponentData(component: any, file: string, successCallback: any = (data: any) => { /* */ }, failCallback: any = (e: any) => { /* */ }): void {
    const token = component.getAccessToken();

    // if (Object.keys(component.$store.state.selected.file).length === 0 || component.$store.state.selected.file.pid !== file) {
    FileDataService.get(token, file)
      .then((response: any) => {
        component.$store.dispatch('setSelectedFile', response.data.data);

        successCallback(response.data.data);
      })
      .catch((e: Error) => {
        failCallback(e);
      });
    /* } else {
      successCallback(component.$store.state.selected.file);
    } */
  }
}

export default FileDataHelper;
