import http from '@/config/http-common';
import File from '@/types/File';

class FinanceInfoDataService {
  static create(token: string|null, file: File, form: any): Promise<any> {
    return http.post(`/file/${file.pid}/finance_info/create`, form, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}

export default FinanceInfoDataService;
