import http from '@/config/http-common';

class FileDataService {
  static create(token: string|null, simulation: string, extra = {} as any): Promise<any> {
    return http.post('/file/create', {
      simulation,
      ...extra,
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static get(token: string|null, file: string): Promise<any> {
    return http.get(`/file/${file}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static download(token: string|null, file: string): Promise<any> {
    return http.post(`/file/${file}/download`, {}, {
      responseType: 'arraybuffer',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}

export default FileDataService;
