
import { defineComponent } from 'vue';
import globals from '../../helpers/globals';
import FormButton from '../../components/forms/Button.vue';
import FormInput from '../../components/forms/Input.vue';
import FileDataHelper from '../../helpers/FileDataHelper';
import FinanceInfoDataService from '../../services/FinanceInfoDataService';

export default defineComponent({
  name: 'FinanceInfo',
  mixins: [
    globals,
  ],
  components: {
    FormButton,
    FormInput,
  },
  data() {
    return {
      form: {
        inputs: {
          product_amount: {
            rule: 'required',
            value: null,
            error: null,
          } as {[key: string]: any},
          duration: {
            rule: 'required',
            value: null,
            error: null,
          } as {[key: string]: any},
          waranty: {
            rule: 'required',
            value: null,
            error: null,
          } as {[key: string]: any},
        } as {[key: string]: any},
      },
    };
  },
  created() {
    const vm = this;

    /* LOAD DATA WHEN COMPONENT IS CREATED */
    this.$store.dispatch('isLoading', true);

    this.load((data: any) => {
      if (vm.$store.state.selected.segment.pid === 'pro' && ['ijara'].includes(vm.$store.state.selected.product.pid)) {
        vm.form.inputs.turnover = {
          rule: 'required|numeric|min:1',
          value: null,
          error: null,
        } as {[key: string]: any};
      }

      if (vm.$store.state.selected.segment.pid === 'corp' && ['exploit-sign', 'exploit-caisse', 'ijara'].includes(vm.$store.state.selected.product.pid)) {
        vm.form.inputs.capital = {
          rule: 'required|numeric|min:1',
          value: null,
          error: null,
        } as {[key: string]: any};
      }

      vm.init();
    });
  },
  mounted() {
    /* Scroll page to top (case of mobile phones) */
    this.scrollToTop();
  },
  methods: {
    /* COMPONENT INITIALIZER */
    init() {
      /* FLAG STEP AS UNCOMPLETED */
      this.setStepAsCompleted(this, false);

      /* MOVE PROGRESS BAR TO VALUE */
      this.progress(this);

      /* SHOW SIDE AND NAV BAR IF USER FALLS DIRECTLY INTO CURRENT COMPONENT */
      this.$store.dispatch('toggleProgressBar', true);
      this.$store.dispatch('toggleSideBar', true);

      /* STOP LOADING */
      this.$store.dispatch('isLoading', false);
    },
    /* LOAD DATA THROUGH APIS */
    load(callback: any) {
      FileDataHelper.prepareComponentData(
        this,
        this.$route.params.file as string,
        callback,
      );
    },
    goToNextStep(event: any) {
      const vm = this;
      event.preventDefault();
      this.$store.dispatch('isLoading', true);

      // Load recaptcha token
      this.recaptcha(this)
        .then((resp: any) => {
          const token = this.getAccessToken();

          const dataForm = {
            product_amount: vm.form.inputs.product_amount.value,
            duration: vm.form.inputs.duration.value,
            waranty: vm.form.inputs.waranty.value,
            recaptcha: vm.$store.state.recaptcha,
          } as {[key: string]: any};

          if ('turnover' in this.form.inputs) {
            dataForm.turnover = vm.form.inputs.turnover.value;
          }

          if ('capital' in this.form.inputs) {
            dataForm.capital = vm.form.inputs.capital.value;
          }

          FinanceInfoDataService.create(token, this.$store.state.selected.file, dataForm).then((responses: any) => {
            vm.setStepAsCompleted(this, true);
          }).catch((e: any) => {
            Object.keys(e.response.data.data.errors).forEach((name) => {
              [vm.form.inputs[name].error] = e.response.data.data.errors[name];
            });
          });
        });
    },
    disabledSubmitButton() {
      let error = false;

      Object.values(this.form.inputs).every((element: any) => {
        if ((element.value === null && element.rule.includes('required')) || element.error !== null) {
          error = true;
          return false;
        }

        return true;
      });

      return error;
    },
  },
});
